<template>
  <div>
    <section class="section-info" id="profile">
      <div class="jcard-main jcard-dark">
        <!-- general -->
        <div class="appointment-grid">
          <div class="jtitle">
            <h3>User Profile</h3>
          </div>

          <div class="jcard-border">
            <div class="jcard-info-item">
              <p class="p1-platform p-grey">ID</p>
              <p class="p1-platform">{{ $C.getTrimmedId(userId) }}</p>
            </div>
          </div>

          <div class="jcard-border">
            <div class="jcard-info-item">
              <p class="p1-platform p-grey">Type</p>
              <p class="p1-platform">{{ userType }}</p>
            </div>
          </div>

          <div class="jcard-border">
            <div class="jcard-info-item">
              <p class="p1-platform p-grey">Email</p>
              <p class="p1-platform">{{ user.email }}</p>
            </div>
          </div>
        </div>

        <div class="btn-container-center">
          <div class="jbtn jbtn-white" @click="showChangeEmailDialog">Change Email</div>
        </div>

        <!-- separator hr -->
        <div class="line"></div>

        <!-- personal info -->
        <div class="jtitle jtitle-w-btn">
          <h3>Personal info</h3>
          <!-- <div @click="showDialog" class="p-grey jbtn-text ml-3">
            <span class="fa fa-pencil"></span>
          </div> -->
        </div>

        <div class="profile-grid-2">
          <!-- name -->
          <div class="jcard-border">
            <div class="jcard-info-item">
              <p class="p1-platform p-grey">Name</p>
              <p class="p1-platform">{{ user.firstName + " " + user.lastName }}</p>
            </div>
          </div>

          <!-- phone -->
          <div class="jcard-border">
            <div class="jcard-info-item">
              <p class="p1-platform p-grey">Phone</p>
              <p class="p1-platform">{{ user.tel }}</p>
            </div>
          </div>

          <!-- street -->
          <div class="jcard-border">
            <div class="jcard-info-item">
              <p class="p1-platform p-grey">Address</p>
              <p class="p1-platform">{{ user.addr }}</p>
            </div>
          </div>

          <!-- zip -->
          <div class="jcard-border">
            <div class="jcard-info-item">
              <p class="p1-platform p-grey">Zip Code</p>
              <p class="p1-platform">{{ user.zip }}</p>
            </div>
          </div>

          <!-- city -->
          <div class="jcard-border">
            <div class="jcard-info-item">
              <p class="p1-platform p-grey">City</p>
              <p class="p1-platform">{{ user.city }}</p>
            </div>
          </div>

          <!-- country -->
          <div class="jcard-border">
            <div class="jcard-info-item">
              <p class="p1-platform p-grey">Country</p>
              <p class="p1-platform">{{ user.country }}</p>
            </div>
          </div>

          <!-- birth date -->
          <div class="jcard-border" v-if="!userIsPress">
            <div class="jcard-info-item">
              <p class="p1-platform p-grey">Date of birth</p>
              <p class="p1-platform">{{ user.birthday }}</p>
            </div>
          </div>

          <!-- birth date -->
          <div class="jcard-border">
            <div class="jcard-info-item">
              <p class="p1-platform p-grey">Created date</p>
              <p class="p1-platform">{{ createdOn }}</p>
            </div>
          </div>
        </div>

        <div class="btn-container-center">
          <button class="jbtn jbtn-white" @click="showDialog">Edit</button>
        </div>

        <!-- separator hr -->
        <div class="line"></div>

        <!-- PRESS - COMPANY INFO -->
        <div v-if="userIsPress">
          <!-- company info -->
          <div class="jtitle jtitle-w-btn">
            <h3>Company info</h3>
            <!-- <div @click="showDialog" class="p-grey jbtn-text ml-3">
              <span class="fa fa-pencil"></span>
            </div> -->
          </div>

          <!-- press id -->
          <div class="jcard-border mb-3">
            <div class="jcard-info-item">
              <p class="p1-platform p-grey">Press ID</p>
              <p class="p1-platform">{{ company.pressId }}</p>
            </div>
          </div>

          <div class="profile-grid-2">
            <!-- name -->
            <div class="jcard-border">
              <div class="jcard-info-item">
                <p class="p1-platform p-grey">Name</p>
                <p class="p1-platform">{{ company.name }}</p>
              </div>
            </div>

            <!-- phone -->
            <div class="jcard-border">
              <div class="jcard-info-item">
                <p class="p1-platform p-grey">Phone</p>
                <p class="p1-platform">{{ company.phone }}</p>
              </div>
            </div>

            <!-- street -->
            <div class="jcard-border">
              <div class="jcard-info-item">
                <p class="p1-platform p-grey">Address</p>
                <p class="p1-platform">{{ company.addr }}</p>
              </div>
            </div>

            <!-- zip -->
            <div class="jcard-border">
              <div class="jcard-info-item">
                <p class="p1-platform p-grey">Zip Code</p>
                <p class="p1-platform">{{ company.zip }}</p>
              </div>
            </div>

            <!-- city -->
            <div class="jcard-border">
              <div class="jcard-info-item">
                <p class="p1-platform p-grey">City</p>
                <p class="p1-platform">{{ company.city }}</p>
              </div>
            </div>

            <!-- country -->
            <div class="jcard-border">
              <div class="jcard-info-item">
                <p class="p1-platform p-grey">Country</p>
                <p class="p1-platform">{{ company.country }}</p>
              </div>
            </div>
          </div>

          <div class="btn-container-center">
            <button class="jbtn jbtn-white" @click="showDialog">Edit</button>
          </div>

          <!-- separator hr -->
          <div class="line"></div>
        </div>

        <!-- DELIVERY ADDRESS -->
        <div v-else>
          <div class="jtitle jtitle-w-btn">
            <h3>Delivery Address</h3>
            <!-- <div @click="showDeliveryDialog" class="p-grey jbtn-text ml-3">
              <span class="fa fa-pencil"></span>
            </div> -->
          </div>
          <div class="jtitle small">Required for a smooth payment and delivery flow.</div>

          <!-- country -->
          <div class="jcard-border mb-3">
            <div class="jcard-info-item">
              <p class="p1-platform p-grey">Country</p>
              <p class="p1-platform">{{ deliveryAddress.country }}</p>
            </div>
          </div>

          <div class="profile-grid-2">
            <!-- name -->
            <div class="jcard-border">
              <div class="jcard-info-item">
                <p class="p1-platform p-grey">Name/Company</p>
                <p class="p1-platform">{{ deliveryAddress.name }}</p>
              </div>
            </div>
            <!-- address 1 -->
            <div class="jcard-border">
              <div class="jcard-info-item">
                <p class="p1-platform p-grey">Address line 1</p>
                <p class="p1-platform">{{ deliveryAddress.line1 }}</p>
              </div>
            </div>

            <!-- address 2 -->
            <div class="jcard-border">
              <div class="jcard-info-item">
                <p class="p1-platform p-grey">Address line 2</p>
                <p class="p1-platform">{{ deliveryAddress.line2 }}</p>
              </div>
            </div>

            <!-- zip -->
            <div class="jcard-border">
              <div class="jcard-info-item">
                <p class="p1-platform p-grey">Zip</p>
                <p class="p1-platform">{{ deliveryAddress.zip }}</p>
              </div>
            </div>

            <!-- city -->
            <div class="jcard-border">
              <div class="jcard-info-item">
                <p class="p1-platform p-grey">City</p>
                <p class="p1-platform">{{ deliveryAddress.city }}</p>
              </div>
            </div>

            <!-- state -->
            <div v-if="deliveryAddress.state" class="jcard-border">
              <div class="jcard-info-item">
                <p class="p1-platform p-grey">State</p>
                <p class="p1-platform">{{ deliveryAddress.state }}</p>
              </div>
            </div>
          </div>
        </div>

        <div class="btn-container-center">
          <button class="jbtn jbtn-white" @click="showDeliveryDialog">Edit Delivery</button>
        </div>

        <!-- separator hr -->
        <div class="line"></div>

        <!-- personal info -->
        <div class="jtitle">
          <h3>Email verification</h3>
        </div>

        <div>
          <label><strong><i class="fal fa-envelope"></i> Your email address is</strong></label>
          <span v-if="!authUser.emailVerified" class="badge badge-danger ml-2">unverified</span>
          <span v-else class="badge badge-success ml-2">verified</span>
        </div>

        <div v-if="!authUser.emailVerified" class="btn-container-center">
          <button class="jbtn jbtn-white jbtn-outline jbtn-xs" @click="sendEmailVerification">Verify</button>
        </div>
      </div>
    </section>

    <!-- ----- edit user profile modal --------------------------------- -->
    <b-modal :hide-header="true" :hide-footer="true" id="edit-user-modal" ref="edit-user-modal" centered
      title="BootstrapVue">
      <div class="jdialog-top">
        <div class="close-container">
          <h3><strong>Edit profile</strong></h3>
          <span @click="$bvModal.hide('edit-user-modal')" class="ml-auto jclose"><i class="fa fa-times"></i></span>
        </div>
        <p><small>Keep your information up to date.</small></p>
      </div>
      <div class="jdialog-main">
        <div class="form-row">
          <div class="col">
            <div class="form-group">
              <label for="first-name"> <i class="fal fa-user"></i> First Name </label>
              <input type="text" v-bind:class="{
                'form-control': true,
                'is-invalid': !validName(editData.firstName) && bluredFirstName,
              }" v-on:blur="bluredFirstName = true" v-model="editData.firstName" />
              <div class="invalid-feedback">Please tell us your name.</div>
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label for="last-name"> <i class="fal fa-user"></i> Last Name </label>
              <input type="text" v-bind:class="{
                'form-control': true,
                'is-invalid': !validName(editData.lastName) && bluredLastName,
              }" v-on:blur="bluredLastName = true" v-model="editData.lastName" />
              <div class="invalid-feedback">Please tell us your name.</div>
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="col-md">
            <div class="form-group">
              <label for="address"><i class="fal fa-map-marker-alt"></i> Address</label>
              <input type="text" v-bind:class="{
                'form-control': true,
                'is-invalid': !validInput(editData.addr) && bluredAddress,
              }" v-on:blur="bluredAddress = true" v-model="editData.addr" />
              <div class="invalid-feedback">Please enter an address.</div>
            </div>
          </div>

          <div class="col-md">
            <div class="form-group">
              <label for="zip"><i class="fal fa-map-marker-alt"></i> Zip</label>
              <input type="text" v-bind:class="{
                'form-control': true,
                'is-invalid': !validInput(editData.zip) && bluredZip,
              }" v-on:blur="bluredZip = true" v-model="editData.zip" />
              <div class="invalid-feedback">Please enter a zip code.</div>
            </div>
          </div>
        </div>

        <div class="form-row">
          <div class="col-md">
            <div class="form-group">
              <label for="city"><i class="fal fa-map-marker-alt"></i> City</label>
              <input type="text" v-bind:class="{
                'form-control': true,
                'is-invalid': !validInput(editData.city) && bluredCity,
              }" v-on:blur="bluredCity = true" v-model="editData.city" />
              <div class="invalid-feedback">Please enter a city.</div>
            </div>
          </div>

          <div class="col-md">
            <div class="form-group">
              <label for="phone"><i class="fal fa-phone-alt"></i> Phone</label>
              <input type="text" v-bind:class="{
                'form-control': true,
                'is-invalid': !validPhone(editData.tel) && bluredTel,
              }" v-on:blur="bluredTel = true" v-model="editData.tel" />
              <div class="invalid-feedback">Please enter a phone number.</div>
            </div>
          </div>
        </div>

        <!-- Press ID -->
        <div class="col-md" v-if="userIsPress">
          <div class="form-group">
            <label for="press-id"><i class="fal fa-phone-alt"></i> Press ID</label>
            <input type="text" v-bind:class="{
              'form-control': true,
              'is-invalid': !validInput(editDataCompany.pressId) && bluredCompanyPressId,
            }" v-on:blur="bluredCompanyPressId = true" v-model="editDataCompany.pressId" />
            <div class="invalid-feedback">Please enter a press.</div>
          </div>
        </div>

        <div class="form-group">
          <label for="country"><i class="fal fa-globe-europe"></i> Country</label>
          <b-form-select v-model="editData.country" text-field="name" value-field="name"
            :options="countriesList"></b-form-select>
        </div>
      </div>

      <!-- Company infos for press -->
      <div v-if="userIsPress" class="jdialog-main">
        <p><small>Company Info</small></p>
        <br />
        <div class="form-row">
          <div class="col">
            <div class="form-group">
              <label for="name"> <i class="fal fa-user"></i> Name </label>
              <input type="text" v-bind:class="{
                'form-control': true,
                'is-invalid': !validName(editDataCompany.name) && bluredCompanyName,
              }" v-on:blur="bluredCompanyName = true" v-model="editDataCompany.name" />
              <div class="invalid-feedback">Please tell us the name.</div>
            </div>
          </div>
        </div>

        <div class="form-row">
          <div class="col-md">
            <div class="form-group">
              <label for="email"><i class="fal fa-phone-alt"></i> Phone</label>
              <input type="text" v-bind:class="{
                'form-control': true,
                'is-invalid': !validPhone(editDataCompany.phone) && bluredCompanyPhone,
              }" v-on:blur="bluredCompanyPhone = true" v-model="editDataCompany.phone" />
              <div class="invalid-feedback">Please enter a phone number.</div>
            </div>
          </div>

          <div class="col-md">
            <div class="form-group">
              <label for="email"><i class="fal fa-map-marker-alt"></i> Address</label>
              <input type="text" v-bind:class="{
                'form-control': true,
                'is-invalid': !validInput(editData.addr) && bluredCompanyAddress,
              }" v-on:blur="bluredCompanyAddress = true" v-model="editDataCompany.addr" />
              <div class="invalid-feedback">Please enter an address.</div>
            </div>
          </div>
        </div>

        <div class="form-row">
          <div class="col-md">
            <div class="form-group">
              <label for="email"><i class="fal fa-map-marker-alt"></i> Zip</label>
              <input type="text" v-bind:class="{
                'form-control': true,
                'is-invalid': !validInput(editDataCompany.zip) && bluredCompanyZip,
              }" v-on:blur="bluredCompanyZip = true" v-model="editDataCompany.zip" />
              <div class="invalid-feedback">Please enter a zip code.</div>
            </div>
          </div>

          <div class="col-md">
            <div class="form-group">
              <label for="email"><i class="fal fa-city"></i> City</label>
              <input type="text" class="form-control" v-model="editDataCompany.city" />
              <div class="invalid-feedback">Please enter a city.</div>
            </div>
          </div>
        </div>

        <div class="form-row">
          <label for="email"><i class="fal fa-globe-europe"></i> Country</label>
          <b-form-select v-model="editDataCompany.country" text-field="code" value-field="code"
            :options="countriesList"></b-form-select>
        </div>
      </div>

      <b-alert v-if="showInputError" show variant="danger"><i class="fad fa-exclamation-circle"></i>
        {{ validationMsg }}
      </b-alert>

      <div class="jdialog-bottom with-cancel">
        <button @click="$bvModal.hide('edit-user-modal')" class="jbtn jbtn-dark">
          <i class="fa fa-times"></i> Cancel
        </button>
        <button @click="updateUserInfo" class="jbtn jbtn-dark"><i class="fa fa-save"></i> Update</button>
      </div>
    </b-modal>

    <!-- ----- edit delivery address modal --------------------------------- -->
    <b-modal :hide-header="true" :hide-footer="true" id="edit-delivery-address-modal" ref="edit-delivery-address-modal"
      centered title="BootstrapVue">
      <div class="jdialog-top">
        <div class="close-container">
          <h3><strong>Edit Delivery</strong></h3>
          <span @click="$bvModal.hide('edit-delivery-address-modal')" class="ml-auto jclose"><i
              class="fa fa-times"></i></span>
        </div>
        <p><small>Keep your information up to date for a smooth payment and delivery flow.</small></p>
      </div>

      <!-- DELIVERY ADDRESS -->

      <div class="jdialog-main">
        <!-- name -->
        <div class="form-group">
          <label for="name"><i class="fal fa-user"></i> Name/Company</label>
          <input type="text" v-bind:class="{
            'form-control': true,
            'is-invalid': !validInput(editDataDelivery.name) && bluredDeliveryName
          }" v-on:blur="bluredDeliveryName = true" v-model="editDataDelivery.name" />
        </div>

        <div class="form-row">
          <!-- address 1 -->
          <div class="col-md">
            <div class="form-group">
              <label for="address"><i class="fal fa-map-marker-alt"></i> Address line 1</label>
              <input type="text" v-bind:class="{
                'form-control': true,
                'is-invalid': !validInput(editDataDelivery.line1) && bluredDeliveryLine1,
              }" v-on:blur="bluredDeliveryLine1 = true" v-model="editDataDelivery.line1" />
              <div class="invalid-feedback">Please enter an address.</div>
            </div>
          </div>

          <!-- address 2 -->
          <div class="col-md">
            <div class="form-group">
              <label for="address"><i class="fal fa-map-marker-alt"></i> Address line 2</label>
              <input type="text" v-bind:class="{
                'form-control': true,
              }" v-model="editDataDelivery.line2" />
            </div>
          </div>
        </div>

        <div class="form-row">
          <!-- zip -->
          <div class="col-md">
            <div class="form-group">
              <label for="zip"><i class="fal fa-map-marker-alt"></i> Zip</label>
              <input type="text" v-bind:class="{
                'form-control': true,
                'is-invalid': !validInput(editDataDelivery.zip) && bluredDeliveryZip,
              }" v-on:blur="bluredDeliveryZip = true" v-model="editDataDelivery.zip" />
              <div class="invalid-feedback">Please enter a zip code.</div>
            </div>
          </div>

          <!-- city -->
          <div class="col-md">
            <div class="form-group">
              <label for="city"><i class="fal fa-map-marker-alt"></i> City</label>
              <input type="text" v-bind:class="{
                'form-control': true,
                'is-invalid': !validInput(editDataDelivery.city) && bluredDeliveryCity,
              }" v-on:blur="bluredDeliveryCity = true" v-model="editDataDelivery.city" />
              <div class="invalid-feedback">Please enter a city.</div>
            </div>
          </div>
        </div>

        <!-- country -->
        <div class="form-group">
          <label for="country"><i class="fal fa-globe-europe"></i> Country</label><br />
          <select class="form-control" @change="updateSelectedCountry($event.target.selectedIndex)"
            v-model="editDataDelivery.country">
            <option v-for="country in countriesList" :key="country.code">{{ country.name }}</option>
          </select>
        </div>

        <!-- state -->
        <div v-if="stateRequired" class="form-group">
          <label for="state"> <i class="fal fa-map-marker-alt"></i> State </label>
          <select class="form-control" @change="updateSelectedState($event.target.selectedIndex)" v-bind:class="{
            'form-control': true,
            'is-invalid': !validInput(editDataDelivery.state) && bluredDeliveryState,
          }" v-on:blur="bluredDeliveryState = true" v-model="editDataDelivery.state">
            <option v-for="state in statesList" :key="state.code">{{ state.name }}</option>
          </select>
          <div class="invalid-feedback">Please select a state.</div>
        </div>
      </div>

      <!-- alert -->
      <b-alert v-if="showInputError" show variant="danger"><i class="fad fa-exclamation-circle"></i>
        {{ validationMsg }}
      </b-alert>

      <div class="jdialog-bottom with-cancel mt-4">
        <!-- <button @click="$bvModal.hide('edit-delivery-address-modal')" class="jbtn jbtn-sm jbtn-red">
          <i class="fa fa-times"></i> Cancel
        </button> -->
        <button @click="updateDeliveryAddress" class="jbtn jbtn-dark jbtn-filter">
          <i class="fa fa-save"></i> Update
        </button>
      </div>
    </b-modal>

    <!-- ----- change email modal --------------------------------- -->
    <b-modal :hide-header="true" :hide-footer="true" id="change-email-modal" ref="change-email-modal" centered
      title="BootstrapVue">
      <div class="jdialog-top">
        <div class="close-container">
          <h3><strong>Change email</strong></h3>
          <span @click="$bvModal.hide('change-email-modal')" class="ml-auto jclose"><i class="fa fa-times"></i></span>
        </div>
        <p>
          <small>In order to change your email, please re-enter your password.<br />Note that you will need to use
            your new email to login.</small>
        </p>
      </div>
      <div class="jdialog-main">
        <div class="form-row">
          <div class="col">
            <div class="form-group">
              <label for="name"> <i class="fal fa-lock"></i> Current password </label>
              <input type="password" v-bind:class="{
                'form-control': true,
                'is-invalid': !validPassword(currentPassword) && bluredPassword,
              }" v-on:blur="bluredPassword = true" v-model="currentPassword" />
              <div class="invalid-feedback">Please enter your current password.</div>
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="col-md">
            <div class="form-group">
              <label for="email"><i class="fal fa-envelope"></i> New email</label>
              <input type="email" v-bind:class="{
                'form-control': true,
                'is-invalid': !validEmail(editEmailData.email) && bluredEmail,
              }" v-on:blur="bluredEmail = true" v-model="editEmailData.email" />
              <div class="invalid-feedback">Please enter a valid email.</div>
            </div>
          </div>
        </div>

        <b-alert v-if="showInputError" show variant="danger"><i class="fad fa-exclamation-circle"></i>
          {{ validationMsg }}
        </b-alert>
      </div>
      <div class="jdialog-bottom with-cancel">
        <button @click="$bvModal.hide('change-email-modal')" class="jbtn jbtn-dark">
          <i class="fa fa-times"></i> Cancel
        </button>
        <button @click="initUpdateEmail" class="jbtn jbtn-dark">
          <span v-if="isSaving" key="spinner"><i class="fad fa-spinner-third fa-spin"></i></span>
          <span v-else key="button"><i class="fa fa-save"></i> Change</span>
        </button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import CL from "@/countries";
import toast from "@/assets/js/toast";
import C from "@/const";
import firebase from "@/firebase/firebaseInit";
import moment from "moment";
const db = firebase.db;
const auth = firebase.auth;
const emailAuthProvider = firebase.emailAuthProvider;

export default {
  name: "Profile",
  data() {
    return {
      isLoading: false,
      listenerRegistration: null,
      userId: "",

      //form error alert
      showInputError: false,
      validationMsg: "",

      //form button
      isSaving: false,

      //edit email form
      validChangeEmail: false,
      currentPassword: "",
      bluredPassword: false,
      bluredEmail: false,
      editEmailData: {
        email: "",
      },
      //edit user info form
      valid: false,
      bluredFirstName: false,
      bluredLastName: false,
      bluredAddress: false,
      bluredCity: false,
      bluredZip: false,
      bluredTel: false,
      // validName: false,
      // validAddr: true,
      // validZip: true,

      // edit delivery info
      bluredDeliveryName: false,
      bluredDeliveryLine1: false,
      // bluredDeliveryLine2: false, <= not mandatory
      bluredDeliveryZip: false,
      bluredDeliveryCity: false,
      bluredDeliveryState: false,

      // edit press info
      bluredCompanyPressId: false,
      bluredCompanyName: false,
      bluredCompanyPhone: false,
      bluredCompanyAddress: false,
      bluredCompanyZip: false,

      editData: {
        firstName: "",
        lastName: "",
        addr: "",
        zip: "",
        city: "",
        country: "Luxembourg",
        tel: "",
      },
      editDataCompany: {
        pressId: "",
        name: "",
        email: "",
        phone: "",
        addr: "",
        zip: "",
        city: "",
        country: "Luxembourg",
      },
      editDataDelivery: {
        name: "",
        country: "Luxembourg",
        countryCode: "LU",
        line1: "",
        line2: "",
        zip: "",
        city: "",
        state: "",
        stateCode: "",
      },
      countriesList: CL.COUNTRIES_ALPHA2,
    };
  },
  methods: {
    showChangeEmailDialog() {
      this.$refs["change-email-modal"].show();
    },
    initUpdateEmail() {
      this.isSaving = true;
      if (!this.validateChangeEmail()) {
        this.isSaving = false;
        return;
      }

      //re-reauthenticate user first, than try updating email
      this.reauthenticate();
    },
    updateEmail() {
      var user = auth.currentUser;

      //update firebase auth
      user
        .updateEmail(this.editEmailData.email)
        .then(() => {
          // Update successful.
          //update firebase user doc
          var docRef = db.collection(C.COLLECTION.USERS).doc(user.uid);
          docRef
            .set(this.editEmailData, { merge: true })
            .then(() => {
              //reset data
              this.currentPassword = "";
              this.editEmailData.email = "";
              this.bluredEmail = false;
              this.bluredPassword = false;
              this.isSaving = false;
              //hide modal
              this.$refs["change-email-modal"].hide();
              //show toast
              toast.success("Your email has been udated.");
            })
            .catch((error) => {
              toast.error("Couldn't update email. " + error.message);
              this.isSaving = false;
            });
        })
        .catch((error) => {
          toast.error("Couldn't update email. " + error.message);
          this.isSaving = false;
        });
    },
    showDialog() {
      this.editData.firstName = this.user.firstName;
      this.editData.lastName = this.user.lastName;
      this.editData.addr = this.user.addr;
      this.editData.city = this.user.city;
      this.editData.zip = this.user.zip;
      this.editData.country = this.user.country;
      this.editData.tel = this.user.tel;
      // press info:
      if (this.userIsPress && this.user.company) {
        this.editDataCompany.pressId = this.user.company.pressId;
        this.editDataCompany.name = this.user.company.name;
        this.editDataCompany.phone = this.user.company.phone;
        this.editDataCompany.addr = this.user.company.addr;
        this.editDataCompany.city = this.user.company.city;
        this.editDataCompany.zip = this.user.company.zip;
        this.editDataCompany.country = this.user.company.country;
      }
      this.$refs["edit-user-modal"].show();
    },
    showDeliveryDialog() {
      if (this.user.delivery) {
        this.editDataDelivery.name = this.user.delivery.name;
        this.editDataDelivery.country = this.user.delivery.country;
        this.editDataDelivery.countryCode = this.user.delivery.countryCode;
        this.editDataDelivery.line1 = this.user.delivery.line1;
        this.editDataDelivery.line2 = this.user.delivery.line2;
        this.editDataDelivery.zip = this.user.delivery.zip;
        this.editDataDelivery.city = this.user.delivery.city;

        this.editDataDelivery.state = this.user.delivery.state;
        this.editDataDelivery.stateCode = this.user.delivery.stateCode;
      }
      this.$refs["edit-delivery-address-modal"].show();
    },
    updateUserInfo() {
      if (!this.validate()) return;
      if (this.userIsPress) {
        this.editData.company = this.editDataCompany;
      }
      var docRef = db.collection(C.COLLECTION.USERS).doc(auth.currentUser.uid);
      docRef
        .set(this.editData, { merge: true })
        .then(() => {
          this.$refs["edit-user-modal"].hide();
          toast.success("User profile updated.");
        })
        .catch((error) => {
          toast.error("Couldn't update user profile. " + error.message);
        });
    },
    updateDeliveryAddress() {
      if (!this.validateDeliveryAddress()) return;
      if (this.userIsPress) {
        toast.success("Something went wrong. Please log in again.");
        return;
      }

      var docRef = db.collection(C.COLLECTION.USERS).doc(auth.currentUser.uid);
      docRef
        .update({ delivery: this.editDataDelivery })
        .then(() => {
          this.$refs["edit-delivery-address-modal"].hide();
          toast.success("Delivery address updated.");
        })
        .catch((error) => {
          toast.error("Couldn't update delivery address. " + error.message);
        });
    },
    sendEmailVerification() {
      const emailTrigger = {
        type: C.EMAIL_TYPE.VERIFY,
        press: this.userIsPress,
        actionLink: C.CLUB_URL + this.userType,
        email: auth.currentUser.email,
      };
      db.collection(C.COLLECTION.EMAILS)
        .doc()
        .set(emailTrigger)
        .then(() => {
          toast.success("We have sent you a verification email. Please check your inbox.");
        })
        .catch((error) => {
          toast.error("Sending verification email failed. " + error.message);
        });
    },
    reauthenticate() {
      var user = auth.currentUser;
      var credentials = emailAuthProvider.credential(user.email, this.currentPassword);

      user
        .reauthenticateWithCredential(credentials)
        .then(() => {
          //change email after re-authenticate
          this.updateEmail();
        })
        .catch((error) => {
          this.isSaving = false;
          toast.error(error.message);
        });
    },
    validateChangeEmail() {
      this.bluredPassword = true;
      if (!this.validPassword(this.currentPassword)) {
        this.validChangeEmail = false;
        return false;
      }

      this.bluredEmail = true;
      this.editEmailData.email = this.editEmailData.email.trim().toLowerCase();
      if (!this.validEmail(this.editEmailData.email)) {
        this.validChangeEmail = false;
        return false;
      }

      this.validChangeEmail = true;
      return true;
    },
    validate() {
      //Check first name
      this.bluredFirstName = true;
      if (!this.validName(this.editData.firstName)) {
        this.valid = false;
        return false;
      }
      //Check last name
      this.bluredLastName = true;
      if (!this.validName(this.editData.lastName)) {
        this.valid = false;
        return false;
      }

      this.bluredAddress = true;
      if (!this.validInput(this.editData.addr)) {
        this.valid = false;
        return false;
      }
      this.bluredCity = true;
      if (!this.validInput(this.editData.city)) {
        this.valid = false;
        return false;
      }
      this.bluredZip = true;
      if (!this.validInput(this.editData.zip)) {
        this.valid = false;
        return false;
      }
      this.bluredTel = true;
      if (!this.validPhone(this.editData.tel)) {
        this.valid = false;
        return false;
      }

      // validate Press data
      if (this.userIsPress) {
        // check press id
        this.bluredCompanyPressId = true;
        if (!this.validInput(this.editDataCompany.pressId)) {
          this.valid = false;
          return false;
        }
        // check company name
        this.bluredCompanyName = true;
        if (!this.validName(this.editDataCompany.name)) {
          this.valid = false;
          return false;
        }
        // check company phone
        this.bluredCompanyPhone = true;
        if (!this.validPhone(this.editDataCompany.phone)) {
          this.valid = false;
          return false;
        }
        // check company address
        this.bluredCompanyAddress = true;
        if (!this.validInput(this.editDataCompany.addr)) {
          this.valid = false;
          return false;
        }
        // check company city
        this.bluredCompanyCity = true;
        if (!this.validInput(this.editDataCompany.city)) {
          this.valid = false;
          return false;
        }
        // check company zip
        this.bluredCompanyZip = true;
        if (!this.validInput(this.editDataCompany.zip)) {
          this.valid = false;
          return false;
        }
      }

      this.valid = true;
      return true;
    },
    validateDeliveryAddress() {
      this.bluredDeliveryName = true;
      if (!this.validInput(this.editDataDelivery.name)) {
        return false;
      }

      // address lines: line 1 manadtory, line 2 optional
      this.bluredDeliveryLine1 = true;
      if (!this.validInput(this.editDataDelivery.line1)) {
        return false;
      }

      // zip
      this.bluredDeliveryZip = true;
      if (!this.validInput(this.editDataDelivery.zip)) {
        return false;
      }

      // city
      this.bluredDeliveryCity = true;
      if (!this.validInput(this.editDataDelivery.city)) {
        return false;
      }

      this.bluredDeliveryState = true;
      // state: if country is US or Canada
      if (!(this.editDataDelivery.countryCode == "US") && !(this.editDataDelivery.countryCode == "CA")) {
        this.editDataDelivery.state = "";
        this.editDataDelivery.stateCode = "";
      } else if (!this.validInput(this.editDataDelivery.state)) {
        return false;
      }

      return true;
    },
    validInput(input) {
      if (!input || !(typeof input === "string" || input instanceof String)) return false;
      return input.trim().length > 0;
    },
    validEmail(email) {
      var re = /(.+)@(.+){2,}\.(.+){2,}/;
      return re.test(email.toLowerCase());
    },
    validName(name) {
      return name.trim().length > 2;
    },
    // validAddr(addr) {
    //   return addr.trim().length > 0;
    // },
    // validZip(zip) {
    //   return zip.trim().length > 0;
    // },
    validPhone(phone) {
      return phone ? phone.trim().length > 5 : false;
    },
    validPassword(password) {
      return password.trim().length > 7;
    },
    updateSelectedCountry(index) {
      const code = this.countriesList[index].code;
      this.editDataDelivery.countryCode = code;
      this.editDataDelivery.state = "";
      this.editDataDelivery.stateCode = "";
    },
    updateSelectedState(index) {
      if (!this.stateRequired || !this.statesList[index] || !this.statesList[index].code) {
        this.editDataDelivery.state = "";
        this.editDataDelivery.stateCode = "";
        return;
      }

      this.editDataDelivery.stateCode = this.statesList[index].code;
    },
    // logout() {
    //   auth.signOut().then(() => {
    //     this.$router.replace({ name: "Login" });
    //   });
    // }
  },
  computed: {
    authUser: {
      get() {
        return this.$store.getters.getAuthUser;
      },
    },
    user: {
      get() {
        return this.$store.getters.getUser;
      },
    },
    company: {
      get() {
        var company = {
          pressId: "",
          name: "",
          email: "",
          phone: "",
          addr: "",
          zip: "",
          city: "",
          country: "Luxembourg",
        };
        if (this.$store.getters.getUserType === C.TYPE.PRESS && this.user.company) {
          company = this.user.company;
        }

        return company;
      },
    },
    deliveryAddress: {
      get() {
        var deliveryAddr = {
          firstName: "",
          lastName: "",
          line1: "",
          line2: "",
          city: "",
          zip: "",
          country: "Luxembourg",
          countryCode: "LU",
          state: "",
          stateCode: "",
        };
        if (this.$store.getters.getUserType != C.TYPE.PRESS && this.user.delivery) {
          deliveryAddr = this.user.delivery;
        }
        return deliveryAddr;
      },
    },
    stateRequired: {
      get() {
        return this.editDataDelivery.countryCode == "US" || this.editDataDelivery.countryCode == "CA";
      },
    },
    statesList: {
      get() {
        if (this.editDataDelivery.countryCode == "US") return CL.STATES_ALPHA2_US;
        if (this.editDataDelivery.countryCode == "CA") return CL.STATES_ALPHA2_CA;

        return [];
      },
    },
    userType: {
      get() {
        return this.$store.getters.getUserTypeString;
      },
    },
    userIsPress: {
      get() {
        return this.$store.getters.getUserType === C.TYPE.PRESS;
      },
    },
    createdOn: {
      get() {
        return this.user.createdOn
          ? moment(C.getDateFromTimestamp(this.user.createdOn)).format("DD-MM-YYYY")
          : "";
      },
    },
  },
  beforeCreate() {
    this.unregister = db
      .collection(C.COLLECTION.USERS)
      .doc(auth.currentUser.uid)
      .onSnapshot((doc) => {
        // var source = doc.metadata.hasPendingWrites ? "Local" : "Server";
        this.$store.commit("setUser", doc.data());
      });
  },
  created() {
    this.userId = auth.currentUser.uid.toUpperCase();
  },
  beforeDestroy() {
    if (this.unregister != null) this.unregister();
  },
};
</script>

<style scoped lang="scss">
// @import "../assets/css/talkii.css";
</style>
